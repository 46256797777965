*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  min-height: 100vh;
  line-height: 1.5;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}
/*# sourceMappingURL=index.37b9b7fc.css.map */
